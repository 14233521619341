/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({
  description,
  lang,
  locale,
  meta,
  keywords,
  title,
  image,
  article,
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  let articleFields = []
  let imageFields = []

  if (image) {
    imageFields.push({
      name: `twitter:card`,
      content: `summary_large_image`,
    })
    imageFields.push({
      property: "og:image",
      content: image,
    })
    imageFields.push({
      name: "twitter:image",
      content: image,
    })
  } else {
    imageFields.push({
      name: `twitter:card`,
      content: `summary`,
    })
  }

  if (article.hasOwnProperty("created_time")) {
    articleFields.push({
      property: `og:type`,
      content: "article",
    })

    articleFields.push({
      name: `article:published_time`,
      content: article.created_time,
    })

    if (article.hasOwnProperty("updated_time")) {
      articleFields.push({
        name: "article:modified_time",
        content: article.updated_time,
      })
      articleFields.push({
        property: "og:updated_time",
        content: article.updated_time,
      })
    }

    if (article.hasOwnProperty("section")) {
      articleFields.push({
        name: "article:section",
        content: article.section,
      })
    }
  } else {
    articleFields.push({
      property: `og:type`,
      content: "website",
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:site_name`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:locale`,
          content: locale,
        },
        {
          name: `twitter:creator`,
          content: `@logologydesign`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        ...imageFields,
        ...articleFields,
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  image: "https://www.logology.co/images/social_image.png",
  locale: "en_US",
  keywords: [],
  description: ``,
  article: {},
}

SEO.propTypes = {
  description: PropTypes.string,
  article: PropTypes.object,
  lang: PropTypes.string,
  locale: PropTypes.string,
  image: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
